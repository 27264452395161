import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row g-5 g-xl-8" }
const _hoisted_2 = { class: "col-xl-6" }
const _hoisted_3 = { class: "col-xl-6" }
const _hoisted_4 = { class: "row g-5 g-xl-8" }
const _hoisted_5 = { class: "col-xl-6" }
const _hoisted_6 = { class: "col-xl-6" }
const _hoisted_7 = { class: "row g-5 g-xl-8" }
const _hoisted_8 = { class: "col-xl-6" }
const _hoisted_9 = { class: "col-xl-6" }
const _hoisted_10 = { class: "row g-5 g-xl-8" }
const _hoisted_11 = { class: "col-xl-6" }
const _hoisted_12 = { class: "col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TablesWidget1 = _resolveComponent("TablesWidget1")!
  const _component_TablesWidget2 = _resolveComponent("TablesWidget2")!
  const _component_TablesWidget3 = _resolveComponent("TablesWidget3")!
  const _component_TablesWidget4 = _resolveComponent("TablesWidget4")!
  const _component_TablesWidget5 = _resolveComponent("TablesWidget5")!
  const _component_TablesWidget6 = _resolveComponent("TablesWidget6")!
  const _component_TablesWidget7 = _resolveComponent("TablesWidget7")!
  const _component_TablesWidget8 = _resolveComponent("TablesWidget8")!
  const _component_TablesWidget9 = _resolveComponent("TablesWidget9")!
  const _component_TablesWidget10 = _resolveComponent("TablesWidget10")!
  const _component_TablesWidget11 = _resolveComponent("TablesWidget11")!
  const _component_TablesWidget12 = _resolveComponent("TablesWidget12")!
  const _component_TablesWidget13 = _resolveComponent("TablesWidget13")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_TablesWidget1, { "widget-classes": "card-xl-stretch mb-xl-8" })
      ]),
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_TablesWidget2, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
      ])
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode("div", _hoisted_5, [
        _createVNode(_component_TablesWidget3, { "widget-classes": "card-xl-stretch mb-xl-8" })
      ]),
      _createVNode("div", _hoisted_6, [
        _createVNode(_component_TablesWidget4, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
      ])
    ]),
    _createVNode("div", _hoisted_7, [
      _createVNode("div", _hoisted_8, [
        _createVNode(_component_TablesWidget5, { "widget-classes": "card-xl-stretch mb-xl-8" })
      ]),
      _createVNode("div", _hoisted_9, [
        _createVNode(_component_TablesWidget6, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
      ])
    ]),
    _createVNode("div", _hoisted_10, [
      _createVNode("div", _hoisted_11, [
        _createVNode(_component_TablesWidget7, { "widget-classes": "card-xl-stretch mb-xl-8" })
      ]),
      _createVNode("div", _hoisted_12, [
        _createVNode(_component_TablesWidget8, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
      ])
    ]),
    _createVNode(_component_TablesWidget9, { "widget-classes": "mb-5 mb-xl-8" }),
    _createVNode(_component_TablesWidget10, { "widget-classes": "mb-5 mb-xl-8" }),
    _createVNode(_component_TablesWidget11, { "widget-classes": "mb-5 mb-xl-8" }),
    _createVNode(_component_TablesWidget12, { "widget-classes": "mb-5 mb-xl-8" }),
    _createVNode(_component_TablesWidget13, { "widget-classes": "mb-5 mb-xl-8" })
  ], 64))
}